.franja-login{
  width: 100%;
  background: linear-gradient(to right,#2e4bc0, #4672e6);
  color: white;
  padding: 2rem 0;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #fafafa;
    padding-bottom: 1rem;
  }
  .logo img{
    width: 200px;
  }
  
  .form-int{
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Alineación horizontal (opcional) */
    align-items: center; /* Alineación vertical */
    height: auto; /* Asegura que el contenedor tenga el alto completo de la ventana */
  }

  .form-group {
    margin-bottom: 15px;
    width: 100%;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
    padding-left: 15px;
  }
  
  input {
    width: 100%;
    padding: 15px;
    border-radius:50px;
    border: none;
    background-color: #ececec;
    margin-bottom:1.5rem
  }
  
  select {
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 50px;
    background-color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  option {
    padding: 10px;
    font-size: 16px;
  }
  select:focus {
    border-color: #007bff;
    outline: none;
  }
  button {
    width: 41%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom:1.5rem;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  @media(max-width:780px){
    .franja-login{
      padding: 1rem 0;
    }
    .responsive-login{
      display: none;
    }
    .form-int {
      width: 350px;
      margin: auto;
    }
    .login-container{
      display: block;
    }
    .logo img{
      width: 300px;
    }
    input {
      width: 90%;
      padding: 15px;
    }
    
    button {
      width: 95%;
      padding: 10px;
      background-color: #4D7BBE;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
    .form-int{
      display: block;
      
    }
  }