h1, h2, h3, h4, h5, h6, p, a, span, li, button, label, td, th, input{
  font-family: "Poppins", sans-serif!important;
}
.App {
  text-align: center;
}




