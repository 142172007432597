.pc{
  display: block;
}
.mv{
  display: none;
}
.grid-2{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.grid-3{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.grid-4{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media(max-width:790px){
  .grid-2, .grid-3, .grid-4{
    display: block;
  }
  .pc{
    display: none;
  }
  .mv{
    display: block;
  }
}
body{
  background-color: #fafafa!important;
}
a{
  cursor: pointer;
  text-decoration: none;
  color: #4D7BBE;
}
.regresar-admin{
  width: 4%;
}
.regresar-admin i{
  font-size: 30px;
  padding: 5px;
  color: #4D7BBE;
  cursor: pointer;
}
.menu-list{
  display: flex;
  flex-direction: column;
}
.menu-list a{
  width: 83%;
  margin: auto;
  padding: 10px 0;
  color: white;
}
.selected{
  border-radius: 50px;
  background: linear-gradient(to right, #58d7e3,#0a5ab3);
}
.tabla-gradient{
  background: linear-gradient(to right, #58d7e3,#0a5ab3);
  color: white;
}
.dashboard{
  display: flex;
  height: auto;
  min-height: 100vh;
}
.menu{
  width: 17%;
  border-right: 3px solid #e8e8e8;
  background-color: #ffffff;
}
.menu img{
  width: 50%;
  padding: 2rem;
}
.panel{
  width: 80%;
  /*background-color: #fafafa;*/
}

.contenedor {
  flex-direction: column;
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center;     /* Centra verticalmente */
  height: auto;           /* Opcional: establece una altura del contenedor */
}
.formulario {
    width: 500px;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #fafafa;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  h2 {
    text-align: center;
  }
  
  .campo {
    margin-bottom: 10px;
  }
  button{
    background: linear-gradient(to right,#2e4bc0, #4672e6);
    /*border-radius: 50px;
    background: linear-gradient(to right, #58d7e3, #0a5ab3);*/
    padding: 1rem;
    border-radius: 10px;
    color: white;
    font-weight: 700;
  }
  
  button:hover {
    background: linear-gradient(to right,#2e4bc0c5, #4671e6c3);
  }
  
  p {
    text-align: center;
    font-size: 14px;
  }
@media(max-width:790px){
  .selected span{
    display: none;
  }
  .selected i{
    font-size: 30px;
  }
  .formulario{
    width: 100%;
    padding: 0;
  }
  .hide{
    display: none;
  }
  .panel{
    width: 95%;
    margin: 0 auto;
  }
  table{
    margin: 0!important;
  }
  .dashboard{
    flex-direction: column;
    height: 100vh;
  }
  /*.columna:nth-child(1){
    order: 2;
  }*/
  .menu{
    display: flex;
    width: 100%;
  }
  .menu img{
    width: 100px;
  }
  .menu-list{
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    padding: 0 1rem;
  }
}
/*Usuarios*/
.user-title{
  margin: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-title h1{
  font-size: 24px;
}
.user-title a{
  background: linear-gradient(to right, #58d7e3,#0a5ab3);
  padding: 1rem;
  border-radius: 10px;
  color: white;
  font-weight: 700;
}
@media(max-width:790px){
  .user-title{
    margin: 1rem;
    display: block;
  }
  .user-title a{
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 50px;
    padding: 20px 27px;
  }
  .user-title span{
    display: none;
  }
}
  /*tabla*/
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 5px;
  }
  
  table, th, td {
    border: 2px solid #e8e8e8;
  }
  
  th, td {
    padding: 8px;
    text-align: center;
  }
  /*Informacion del usuario*/
  .usuario-{
    text-align: left;
    margin-left: 3rem;
  }
  .menuUsuario{
    display: flex;
    gap: 1rem;
    margin-left:2rem;
  }
  .menuUsuario a{
    padding: 1rem;
    color: black;
    font-weight: 500;
    font-size: 18px;
  }
  .informacion{
    display: flex;
    margin-top:3rem
  }
  .informacion form{
    width: 40%;
    box-shadow: none;
    text-align: left;
    padding:0 3rem;
  }
  /*.informacion div{
    margin-bottom: 1.5rem;
  }*/
  .info-{
    width: 40%;
    margin: 0 auto;
  }
  .recuadro{
    border: 2px solid rgba(128, 128, 128, 0.387);
    margin-bottom: 1rem;
    padding: 1rem;
    text-align: left;
  }
  .recuadro div{
    display: flex;
    align-items: center;
  }
  .recuadro div h3{
    width: 50%;
    margin: 0;
  }
  .recuadro p{
    text-align: left;
  }
  .recuadro span{
    color: #5f67be;
    margin-left: 1rem;
  }
  @media(max-width:790px){
    .informacion{
      display: block;
    }
    .informacion form{
      width: 100%;
      padding: 0;
    }
    .info-{
      width: 100%;
    }
    .menuUsuario-mv{
      position: fixed;
      bottom: 0;
      left: 0;
      background: rgb(255, 255, 255);
      margin: 0;
      width: 100%;
      display: flex;
      padding: 20px 0;
      justify-content: space-around;
    }
    .menuUsuario-mv i{
      color: rgba(128, 128, 128, 0.387);
      font-size: 35px;
    }
    .recuadro{
      margin-bottom:7rem;
    }
  }
  /*Historial*/
  .historial{
    display: flex;
    flex-direction: column;
    margin: 0 0 0 1rem;
  }
  .panel-info{
    display: flex;
  }
  .panel-info .recuadro{
    width: 50%;
  }
  .panel-info .meses{
    width: 50%;
  }
  .grafica{
    width: 100%;
    border: 2px solid rgba(128, 128, 128, 0.387);
    margin-bottom: 1rem;
  }
  .grafica-tabla{
    display: flex;
    width: 50%;
    margin: auto;
  }
  .mes-item{
    display: flex;
    width: 80%;
    margin: 0 auto 1.5rem auto;
    align-items: center;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
  .mes-item i{
    width: 20%;
    font-size: 30px;
  }
  .mes-item h3, .mes-item p{
    margin: 0;
    text-align: left;
  }
@media(max-width:790px){
  .historial{
    margin:0;
  }
  .panel-info{
    flex-direction: column;
  }
  .panel-info .recuadro{
    width: 90%;
    margin: 0 auto 7rem auto;
  }
  .panel-info .meses{
    width: 100%;
  }
  .grafica-tabla{
    width: 100%;
    height: auto;  
    max-width: 100%; 
  }
}
/*PDF*/
.form_pdf{
  width: 100%!important;
  padding: 0 1rem 0 0!important;
}
.form_pdf input{
  padding: 1rem 5px;
}
/*Empresas*/
.empresas-div{
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.empresa-card{
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50px;
  margin: 5px 10px;
  padding: 30px 10px;
  background: linear-gradient(to bottom, #58d7e3 25%, #4672e6);
  cursor: pointer;
}
.empresa-card i{
  margin: auto;
  font-size: 6Arem;
  color: white;
}
.empresa-card p, .empresa-card h3{
  text-align: center;
  color: white;
}
.historial-btn{
  background: linear-gradient(to right, #2e4bc0, #4672e6);
  border-radius: 50px;
  padding: 9px 30px;
  color: white;
  font-size: 13px;
  margin-left: 5px;
}
@media(max-width:790px){
  .empresas-div{
    display: block;
  }
  .historial-btn{
    display: flex;
    max-width: 80%;
    justify-content: center;
    align-items: center;
    margin: 0 0 10px 0;
  }
}
/*Cards clientes*/
.card{
  border: 1px solid rgb(255, 255, 255);
  border-radius: 50px;
  margin: 5px 10px;
  padding: 30px 10px;
  background-image: url("assets/Fondocard.png");
  background-size: cover;
  z-index: 99999;
}
.buttons-card{
  padding: 0 1rem;
}
.card select{
  border: 2px solid #2e4bc0;
}
.card button{
  margin: 10px 0;
  padding: 5px 5px;
  width: 100%;
}
.icono-card i{
  font-size: 4rem;
  color: white;
  z-index: 9999;
}
.filtro-cards{
  width: 30%;
  border-radius: 10px;
}
/*Formulario de password*/
.form-pass{
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100vh;
}
/*Titulos en light*/
.ligth-tittle{
  font-weight: 300;
}